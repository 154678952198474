<template>
    <div class="counter" :class="inputClasses">
        <span class="minus" @click="minus">-</span>
        <input
            :readonly="readonly"
            v-model="value"
            type="text"
        >
        <span class="plus" @click="plus">+</span>
    </div>
</template>

<script>
export default {
    name: "Counter",
    props: {
        modelValue: {
            type: [String, Number],
            default: 0
        },
        readonly: {
            type: Boolean,
            default: true
        },
        max: {
            type: Number,
            default: 99999
        },
        min: {
            type: Number,
            default: 0
        },
        error: Boolean,
    },
    methods: {
        minus() {
            const value = parseInt(this.modelValue);
            if (value > this.min) {
                this.$emit('update:modelValue', value - 1);
            }
        },
        plus() {
            const value = parseInt(this.modelValue);
            if (value < this.max) {
                this.$emit('update:modelValue', value + 1);
            }
        }
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return parseInt(this.modelValue);
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        },
        inputClasses() {
            return {
                'filled': !!this.modelValue,
                'has-error': this.error,
                'max': this.modelValue === this.max,
                'min': this.modelValue === this.min,
            }
        },
    },
    data() {
        return {
            focus: false,
        }
    }
}
</script>
