<template>
    <div class="autocomplete" :class="inputClasses">
        <div class="label" v-if="label.length">{{ label }}</div>
        <div class="field">
            <div class="selected-list" v-if="value">
                <div
                    class="selected"
                    v-for="(item,index) in selectedItems"
                    :key="index"
                    @click="onRemoveItem(index)"
                >
                    {{ item[itemLabel] }}
                </div>
            </div>
            <input
                type="text"
                ref="input"
                v-if="showField"
                v-model="field"
                :placeholder="placeholder"
                @input="onChange"
                @keydown.down="onKeyDown"
                @keydown.up="onKeyUp"
                @focus="onFocus"
                @blur="onBlur"
                @keydown.enter="onKeyEnter"
            >
            <div class="results" v-if="loading || filterItems.length > 0">
                <div class="loading" v-if="loading">{{ loadingText }}</div>
                <div class="item"
                     v-for="(item, index) in filterItems"
                     :key="index"
                     :class="{active : index === activeIndex}"
                     @click="onSelect(item)"
                >
                    <slot name="result-item" :item="item">
                        {{ item[itemLabel] }}
                    </slot>
                </div>
            </div>
            <slot name="extended"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Autocomplete",
    data() {
        return {
            field: null,
            focus: false,
            loading: false,
            timeout: null,
            activeIndex: -1
        }
    },
    props: {
        modelValue: {
            type: [Object, Array],
        },
        label: {
            type: String,
            default: ""
        },
        multiple: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: "Поиск"
        },
        itemLabel: {
            type: String,
            default: "name"
        },
        loadingText: {
            type: String,
            default: "Пожалуйста подождите ..."
        },
        defaultTimeout: {
            type: Number,
            default: 500
        },
        success: Boolean,
        error: Boolean,
        items: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        onChange() {
            clearTimeout(this.timeout);
            this.loading = true;
            this.timeout = setTimeout(() => {
                this.loading = false;
                this.$emit('async', this.field);
            }, this.defaultTimeout);
        },
        onKeyDown() {
            if (this.activeIndex < this.items.length - 1) {
                this.activeIndex++;
            }
        },
        onKeyUp() {
            if (this.activeIndex > -1) {
                this.activeIndex--;
            }
        },
        onKeyEnter() {
            if (this.activeIndex > -1) {
                this.onSelect(this.items[this.activeIndex]);
                this.$refs.input.blur();
            }
        },
        onSelect(item) {
            if (this.multiple === true) {
                if (!this.value) {
                    this.value = [item];
                } else {
                    this.value.push(item);
                }
            } else {
                this.value = item;
            }
            this.$emit('selectItem', item);
            this.focus = false;
            this.field = '';
        },
        onFocus() {
            this.focus = true;
        },
        onBlur() {
            setTimeout(() => {
                this.focus = false;
            }, 300);
        },
        onRemoveItem(index) {
            if (this.multiple) {
                this.value.splice(index, 1);
            } else {
                this.value = null;
            }
        }
    },
    emits: ['update:modelValue', 'async', 'selectItem'],
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        inputClasses() {
            return {
                'with-label': !!this.label,
                'filled': !!this.modelValue,
                'focus': this.focus,
                'success': this.success,
                'has-error': this.error
            }
        },
        filterItems() {
            return this.items.filter(item => {
                return !(
                    this.value &&
                    (
                        !this.multiple && this.value[this.itemLabel] === item[this.itemLabel] ||
                        this.multiple && this.value
                            .find(itemValue => itemValue[this.itemLabel] === item[this.itemLabel])
                    )
                );
            });
        },
        showField() {
            return !this.multiple
                ? !(this.value)
                : true;
        },
        selectedItems() {
            return this.multiple
                ? this.value
                : [this.value];
        }
    },
    watch: {
        items() {
            this.activeIndex = -1;
        }
    }
}
</script>

<style scoped>

</style>