<template>
    <div class="editor-tab" v-show="show">
        <slot></slot>
    </div>
</template>

<script>

export default {
    name: "EditorTab",
    inject: ['getCurrentTabTitle'],
    props: {
        title: {
            type: String,
            default: 'Tab'
        }
    },
    computed: {
        show() {
            return (this.getCurrentTabTitle() === this.title);
        }
    }
}
</script>

<style scoped>

</style>